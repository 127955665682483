import React from 'react';
import Layout from "../../components/layout";

const Bassendean = () => {
  return (
    <Layout>
      <h1>France</h1>
      <h2>Instructor</h2>
      <p>Sensei Gavin Hall<br/>
        Email: <a href="mailto:gavinsisa@gmail.com">gavinsisa@gmail.com</a><br/>
        Website: <a href="https://togkafrance.weebly.com//">togkafrance.weebly.com</a><br/>
        Phone: (0033) 6 72 53 97 82</p>
    </Layout>);
}

export default Bassendean;
